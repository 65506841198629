import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'RsThC72G1wn8Ku4YQWwcizhqk3q2qFaA'  
})
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import Element from 'element-ui';
// 在main.js中全局引入
// import VideoPlayer from 'vue-video-player/src';
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
import Swiper from "swiper";
import "swiper/css/swiper.min.css";

// Vue.use(VideoPlayer)
Vue.use(ElementUI);
Vue.use(dataV)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
