<template>
  <div>
    <baidu-map id="map" @ready="handler" style="height: 100vh; width: 100vw">
    </baidu-map>

    <div v-for="(farmRecord, index) in farmRecords" :id="farmRecord.farmid">
      <div class="nongchang" :key="index">
        <div class="top">
          <div class="wordwhite">种植情况</div>
          <div class="imgandword">
            <div><img :src="farmRecord.imgSrc" width="150" /></div>
            <div>
              <div class="wordgreen">地块：{{ farmRecord.name }}</div>
              <div class="wordgreen">亩数：{{ farmRecord.area }}</div>
              <div class="wordgreen">品种：{{ farmRecord.crops }}</div>
              <div class="wordgreen">开始时间：{{ farmRecord.startDate }}</div>
              <div class="wordgreen">结束时间：{{ farmRecord.endDate }}</div>
              <div class="wordgreen">
                产量预估：{{ farmRecord.yieldEstimate }}
              </div>
            </div>
          </div>
          <div class="xiushi"></div>
        </div>

        <div class="middle">
          <div class="wordwhite">农事记录</div>
          <div
            ref="chartContainers"
            :ref="`chartContainer${index}`"
            :id="farmRecord.chartid"
          ></div>
          <div class="xiushi"></div>
        </div>

        <div class="bottom">
          <div
            class="tubiaoword"
            v-for="(event, eventIndex) in farmRecord.events"
            :key="eventIndex"
          >
            <div>
              <img src="../assets/工作.png" width="30" />
            </div>
            <div class="fontword">
              <div class="wordwhite">{{ event.date }}</div>
              <div class="wordwhite">{{ event.activity }}</div>
              <div class="wordwhite">{{ event.person }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航栏区域 -->
    <daohang></daohang>
    <!-- 左侧区域 -->
    <left></left>
    <!-- 底部区域 -->
    <bottom></bottom>
    <!-- 右侧区域 -->
  </div>
</template>

<script>
import daohang from "@/components/daohang.vue";
import left from "@/components/left.vue";
import bottom from "@/components/bottom.vue";

import * as echarts from "echarts";

export default {
  components: {
    daohang,
    left,
    bottom,
  },
  data() {
    return {
      farmRecords: [
        // 农场
        {
          farmid: "farmnongchang",
          chartid: "myChartnongchang",
          name: "家庭农场",
          imgSrc: require("../assets/农场.jpg"),
          area: "8.5亩",
          crops: "大蒜、青菜等",
          startDate: "2023.10.14",
          endDate: "2024.6",
          yieldEstimate: "亩产500斤",
          events: [
            { date: "03-04", activity: "除草", person: "张某" },
            { date: "03-04", activity: "除草", person: "张某" },
            { date: "03-04", activity: "除草", person: "张某" },
          ],
          chartoption: {
            toright: "10%",
            totop: "20%",
            legendData: [
              { value: 10, name: "施肥" },
              { value: 8, name: "除草" },
              { value: 1, name: "换水" },
              { value: 1, name: "虫草防控" },
            ],
          },
        },
        // 小龙虾
        {
          farmid: "farmxiaolongxia",
          chartid: "myChartxiaolongxia",
          name: "虾塘",
          imgSrc: require("../assets/龙虾.jpg"),
          area: "43.92亩",
          crops: "小龙虾",
          startDate: "2023.11.13",
          endDate: "2024.05.01",
          yieldEstimate: "亩产150斤",
          events: [
            { date: "12.13", activity: "种水草", person: "常海琴" },
            { date: "02.08", activity: "投料", person: "常海琴" },
            { date: "02.16", activity: "消毒", person: "常海琴" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 10, name: "换水" },
              { value: 8, name: "投料" },
              { value: 1, name: "消毒" },
              { value: 1, name: "种水草" },
            ],
          },
        },
        //大棚上
        {
          farmid: "farmdapengshang",
          chartid: "myChartdapengshang",
          name: "大棚1",
          imgSrc: require("../assets/大棚1.jpg"),
          area: "4亩",
          crops: "甜瓜、黄瓜等",
          startDate: "2023.07.25",
          endDate: "2023.08.31",
          yieldEstimate: "亩产3000斤",
          events: [
            { date: "12.13", activity: "种水草", person: "常海琴" },
            { date: "03-04", activity: "除草", person: "常海琴" },
            { date: "03-04", activity: "除草", person: "常海琴" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "滴灌" },
              { value: 8, name: "施肥" },
              { value: 1, name: "授粉" },
              { value: 1, name: "覆膜" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 大棚下
        {
          farmid: "farmdapengxia",
          chartid: "myChartdapengxia",
          name: "大棚2",
          imgSrc: require("../assets/大棚2.jpg"),
          area: "4亩",
          crops: "阳光玫瑰",
          startDate: "2023.02.05",
          endDate: "2024.5",
          yieldEstimate: "亩产2500斤",
          events: [
            { date: "02.01", activity: "施肥", person: "徐兴强" },
            { date: "02.05", activity: "授粉", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "滴灌" },
              { value: 8, name: "施肥" },
              { value: 1, name: "授粉" },
              { value: 1, name: "覆膜" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        //  鱼塘
        {
          farmid: "farmyutang",
          chartid: "myChartyutang",
          name: "鱼塘",
          imgSrc: require("../assets/鱼塘.jpg"),
          area: "10亩",
          crops: "青鱼、草鱼等",
          startDate: "2023",
          endDate: "2024.2",
          yieldEstimate: "亩产1500斤",
          events: [
            { date: "01.09", activity: "翻糖", person: "章正伟" },
            { date: "03.05", activity: "投料", person: "章正伟" },
            { date: "03.06", activity: "投料", person: "章正伟" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 2, name: "翻糖" },

              { value: 10, name: "换水" },
              { value: 8, name: "投料" },
              { value: 1, name: "消毒" },
            ],
          },
        },
        // 小麦
        {
          farmid: "farmxiaomai",
          chartid: "myChartxiaomai",
          name: "小麦种植区",
          imgSrc: require("../assets/小麦.jpg"),
          area: "163.5亩",
          crops: "镇麦16",
          startDate: "2023.11.28",
          endDate: "2024.5",
          yieldEstimate: "亩产400斤",
          events: [
            { date: "11.28", activity: "施肥", person: "王荣林" },
            { date: "02.07", activity: "施肥", person: "王荣林" },
            { date: "02.17", activity: "施肥", person: "王荣林" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 10, name: "施肥" },
              { value: 8, name: "换水" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 甜瓜
        {
          farmid: "farmtiangua",
          chartid: "myCharttiangua",
          name: "大棚3",
          imgSrc: require("../assets/小西红柿.jpg"),
          area: "8亩",
          crops: "小番茄等",
          startDate: "2023.02.05",
          endDate: "2024.5",
          yieldEstimate: "亩产2500斤",
          events: [
            { date: "02.01", activity: "施肥", person: "徐兴强" },
            { date: "02.05", activity: "授粉", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "滴灌" },
              { value: 8, name: "施肥" },
              { value: 1, name: "授粉" },
              { value: 1, name: "覆膜" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        //草莓
        {
          farmid: "farmcaomei",
          chartid: "myChartcaomei",
          name: "草莓种植区",
          imgSrc: require("../assets/草莓.jpg"),
          area: "2.2亩",
          crops: "草莓",
          startDate: "2023.09.13",
          endDate: "2024.01.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "施肥", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "覆膜" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 水蜜桃
        {
          farmid: "farmshuimitao",
          chartid: "myChartshuimitao",
          name: "水蜜桃种植区",
          imgSrc: require("../assets/水蜜桃.jpg"),
          area: "1.8亩",
          crops: "水蜜桃",
          startDate: "2023.12.05",
          endDate: "2024.06.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "剪枝", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "剪枝" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 柿子
        {
          farmid: "farmshizi",
          chartid: "myChartshizi",
          name: "柿子种植区",
          imgSrc: require("../assets/柿子.jpg"),
          area: "3.6亩",
          crops: "柿子",
          startDate: "2024.01.11",
          endDate: "2024.06.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "施肥", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 樱桃
        {
          farmid: "farmyingtao1",
          chartid: "myChartyingtao1",
          name: "樱桃种植区",
          imgSrc: require("../assets/樱桃.jpg"),
          area: "3.6亩",
          crops: "樱桃",
          startDate: "2024.03.03",
          endDate: "2024.06.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "施肥", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "虫害防控" },
            ],
          },
        },
        // 果桑
        {
          farmid: "farmguosang",
          chartid: "myChartguosang",
          name: "长果桑种植区",
          imgSrc: require("../assets/果桑.jpg"),
          area: "3.6亩",
          crops: "长果桑",
          startDate: "2024.03.03",
          endDate: "2024.06.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "施肥", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "25%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "虫害防控" },
            ],
          },
        },
        //黄桃
        {
          farmid: "farmhuangtao",
          chartid: "myCharthuangtao",
          name: "黄桃种植区",
          imgSrc: require("../assets/黄桃.jpg"),
          area: "2.0亩",
          crops: "黄桃",
          startDate: "2023.12.05",
          endDate: "2024.06.01",
          yieldEstimate: "亩产3600斤",
          events: [
            { date: "09.13", activity: "采摘", person: "徐兴强" },
            { date: "10.17", activity: "剪枝", person: "徐兴强" },
            { date: "03.03", activity: "虫害防控", person: "徐兴强" },
          ],
          chartoption: {
            toright: "10%",
            totop: "15%",
            legendData: [
              { value: 10, name: "采摘" },
              { value: 8, name: "施肥" },

              { value: 1, name: "剪枝" },
              { value: 1, name: "虫害防控" },
            ],
          },
        },
      ],
      showPopup: false,
      map: "",
      mapZoom: 18,
      polygon: "",
      inputValue: "",
      dialogVisible: false,
      markerArr: [],
    };
  },
  methods: {
    // 地图基本设置
    handler({ BMap, map }) {
      // 创建Map实例,map是id=map
      this.map = new BMap.Map("map");

      var point = new BMap.Point(119.9853, 30.7390608);
      // console.log(this.map, "this.map");
      // 添加控件的时候用以下一句话
      // this.map = new BMap.Map("map",{BMAP_SATELLITE_MAP});
      // 初始化地图,设置中心点坐标和地图级别
      this.map.centerAndZoom(point, this.mapZoom);
      // 启用滚轮放大缩小,默认禁用
      this.map.enableScrollWheelZoom(true);

      // 启用地图惯性拖拽,默认禁用
      this.map.enableContinuousZoom(true);
      // 改变地图类型
      this.map.setMapType(BMAP_SATELLITE_MAP);
      // 增加覆盖物
      this.fugaiwu();
    },
    // 设计覆盖物
    fugaiwu() {
      // 农场
      let nongchangtable = [
        new BMap.Point(119.984794, 30.740155),
        new BMap.Point(119.984084, 30.739759),
        new BMap.Point(119.984004, 30.739209),
        new BMap.Point(119.984799, 30.738899),
        new BMap.Point(119.985304, 30.738909),
        new BMap.Point(119.985394, 30.7397),
      ];
      // // 小龙虾
      let xiaolongxiatable = [
        new BMap.Point(119.981794, 30.740065),
        new BMap.Point(119.983234, 30.739205),

        new BMap.Point(119.984804, 30.738709),
        new BMap.Point(119.984804, 30.738609),
        new BMap.Point(119.984204, 30.738559),
        new BMap.Point(119.983399, 30.7378),
        new BMap.Point(119.98307, 30.737729),
        new BMap.Point(119.98269, 30.737199),
        new BMap.Point(119.981699, 30.73767),
        new BMap.Point(119.982089, 30.73829),
        new BMap.Point(119.981794, 30.739179),
        new BMap.Point(119.98129, 30.7395099),
      ];

      // 小麦

      let xiaomaitable = [
        new BMap.Point(119.98299, 30.736699),
        new BMap.Point(119.98273, 30.737199),
        new BMap.Point(119.98312, 30.737729),
        new BMap.Point(119.983399, 30.737749),
        new BMap.Point(119.984204, 30.738521),
        new BMap.Point(119.984804, 30.738578),
        new BMap.Point(119.985299, 30.738648),
        new BMap.Point(119.985894, 30.738809),
        new BMap.Point(119.986994, 30.738809),
        new BMap.Point(119.987494, 30.738729),
        new BMap.Point(119.988194, 30.738679),
        new BMap.Point(119.989094, 30.739059),
        new BMap.Point(119.989094, 30.739399),
        new BMap.Point(119.988704, 30.739709),
        new BMap.Point(119.989124, 30.740281),
        new BMap.Point(119.990204, 30.740881),
        new BMap.Point(119.991004, 30.741981),
        new BMap.Point(119.992104, 30.742511),
        new BMap.Point(119.993499, 30.742511),
        new BMap.Point(119.990204, 30.738601),
        new BMap.Point(119.989094, 30.739059),
        new BMap.Point(119.988904, 30.738059),
        new BMap.Point(119.986994, 30.735459),
        new BMap.Point(119.986734, 30.735519),
        new BMap.Point(119.986614, 30.736069),
        new BMap.Point(119.985314, 30.736069),
        new BMap.Point(119.984864, 30.736469),
        new BMap.Point(119.984064, 30.736369),
      ];

      // 鱼塘
      let yutangtable = [
        new BMap.Point(119.985354, 30.738209),
        new BMap.Point(119.985454, 30.738359),
        new BMap.Point(119.98779, 30.736989),
        new BMap.Point(119.98769, 30.736859),
      ];

      // 大棚1
      let dapeng1table = [
        new BMap.Point(119.985494, 30.737899),
        new BMap.Point(119.985199, 30.737569),
        new BMap.Point(119.985599, 30.737349),
        new BMap.Point(119.985899, 30.737659),
      ];

      // 大棚2
      let dapeng2table = [
        new BMap.Point(119.986009, 30.737629),
        new BMap.Point(119.985719, 30.737249),
        new BMap.Point(119.986109, 30.737049),
        new BMap.Point(119.986409, 30.737409),
      ];

      // 草莓
      let caomeitable = [
        new BMap.Point(119.982022, 30.73821),
        new BMap.Point(119.981689, 30.73829),
        new BMap.Point(119.980999, 30.73749),

        new BMap.Point(119.981299, 30.73727),
      ];

      // 甜瓜、黄瓜、西红柿
      let tianguatable = [
        new BMap.Point(119.981699, 30.73761),
        new BMap.Point(119.981309, 30.73717),
        new BMap.Point(119.981399, 30.73681),
        new BMap.Point(119.981899, 30.73661),
        new BMap.Point(119.982099, 30.73681),
        new BMap.Point(119.981609, 30.73701),
      ];

      // 水蜜桃
      let shuimitaotable = [
        new BMap.Point(119.982482, 30.73891),
        new BMap.Point(119.982352, 30.73873),
        new BMap.Point(119.983652, 30.73806),
        new BMap.Point(119.983699, 30.73826),
      ];

      // 柿子1
      let shizi1table = [
        new BMap.Point(119.985484, 30.738499),
        new BMap.Point(119.986269, 30.738029),
        new BMap.Point(119.986229, 30.737959),
        new BMap.Point(119.985414, 30.738419),
      ];
      // 柿子2
      let shizi2table = [
        new BMap.Point(119.986009, 30.737669),
        new BMap.Point(119.985234, 30.738109),
        new BMap.Point(119.985294, 30.738209),
        new BMap.Point(119.986069, 30.737759),
      ];

      // 樱桃1
      let yingtao1table = [
        new BMap.Point(119.986359, 30.737999),
        new BMap.Point(119.987199, 30.737489),
        new BMap.Point(119.987139, 30.737409),
        new BMap.Point(119.986309, 30.737899),
      ];
      // // 樱桃2
      let yingtao2table = [
        new BMap.Point(119.986139, 30.737699),
        new BMap.Point(119.986079, 30.737629),
        new BMap.Point(119.986909, 30.737169),
        new BMap.Point(119.986959, 30.737229),
      ];

      let yingtao3table = [
        new BMap.Point(119.987259, 30.737411),
        new BMap.Point(119.987209, 30.737351),
        new BMap.Point(119.987809, 30.737011),
        new BMap.Point(119.987849, 30.737041),
      ];

      // 果桑
      let guosangtable = [
        new BMap.Point(119.987039, 30.737109),
        new BMap.Point(119.987069, 30.737159),
        new BMap.Point(119.987699, 30.736799),
        new BMap.Point(119.987599, 30.736799),

        // new BMap.Point(119.987259, 30.737411),
      ];

      // 黄桃
      let huangtaotable = [
        // new BMap.Point(119.982352, 30.73873),
        new BMap.Point(119.982352, 30.73833),
        new BMap.Point(119.983399, 30.7378),

        new BMap.Point(119.983719, 30.73731),

        new BMap.Point(119.985519, 30.73715),

        new BMap.Point(119.986109, 30.737009),

        new BMap.Point(119.987609, 30.736409),
        new BMap.Point(119.987609, 30.736309),
        new BMap.Point(119.986109, 30.736909),
        new BMap.Point(119.985519, 30.73705),
        new BMap.Point(119.983719, 30.73721),
        new BMap.Point(119.983389, 30.7377),
        new BMap.Point(119.982352, 30.73823),
      ];

      const fugaiwudata = [
        {
          name: "农田",
          id: "farmnongchang",
          points: nongchangtable,
          style: {
            strokeWeight: 1,
            strokeColor: "green",
            fillColor: "green",
            fillOpacity: 0.5,
          },
        },
        {
          name: "小龙虾",
          id: "farmxiaolongxia",
          points: xiaolongxiatable,
          style: {
            strokeWeight: 1,
            strokeColor: "#e6c3c3",
            fillColor: "#e6c3c3",
            fillOpacity: 0.4,
          },
        },
        {
          name: "小麦",
          id: "farmxiaomai",
          points: xiaomaitable,
          style: {
            strokeWeight: 1,
            strokeColor: "yellow",
            fillColor: "yellow",
            fillOpacity: 0.5,
          },
        },
        {
          name: "鱼塘",
          id: "farmyutang",

          points: yutangtable,
          style: {
            strokeWeight: 1,
            strokeColor: "blue",
            fillColor: "blue",
            fillOpacity: 0.8,
          },
        },
        {
          name: "大棚1",
          id: "farmdapengshang",

          points: dapeng1table,
          style: {
            strokeWeight: 1,
            strokeColor: "orange",
            fillColor: "orange",
            fillOpacity: 0.7,
          },
        },
        {
          name: "大棚2",
          id: "farmdapengxia",

          points: dapeng2table,
          style: {
            strokeWeight: 1,
            strokeColor: "#470024",
            fillColor: "#470024",
            fillOpacity: 0.7,
          },
        },
        {
          name: "草莓",
          id: "farmcaomei",

          points: caomeitable,
          style: {
            strokeWeight: 1,
            strokeColor: "#2F3895",
            fillColor: "#2F3895",
            fillOpacity: 0.5,
          },
        },
        {
          name: "甜瓜",
          id: "farmtiangua",

          points: tianguatable,
          style: {
            strokeWeight: 1,
            strokeColor: "cyan",
            fillColor: "cyan",
            fillOpacity: 0.5,
          },
        },
        {
          name: "水蜜桃",
          id: "farmshuimitao",

          points: shuimitaotable,
          style: {
            strokeWeight: 1,
            strokeColor: "purple",
            fillColor: "purple",
            fillOpacity: 0.5,
          },
        },
        {
          name: "柿子",
          id: "farmshizi",

          points: shizi1table,
          style: {
            strokeWeight: 1,
            strokeColor: "#ffb3e6",
            fillColor: "#ffb3e6",
            fillOpacity: 0.5,
          },
        },
        {
          name: "柿子",
          id: "farmshizi",

          points: shizi2table,
          style: {
            strokeWeight: 1,
            strokeColor: "#ffb3e6",
            fillColor: "#ffb3e6",
            fillOpacity: 0.5,
          },
        },
        {
          name: "樱桃1",
          id: "farmyingtao1",

          points: yingtao1table,
          style: {
            strokeWeight: 1,
            strokeColor: "red",
            fillColor: "red",
            fillOpacity: 0.7,
          },
        },
        {
          name: "樱桃2",
          id: "farmyingtao2",

          points: yingtao2table,
          style: {
            strokeWeight: 1,
            strokeColor: "red",
            fillColor: "red",
            fillOpacity: 0.7,
          },
        },
        {
          name: "樱桃3",
          id: "farmyingtao3",

          points: yingtao3table,
          style: {
            strokeWeight: 1,
            strokeColor: "red",
            fillColor: "red",
            fillOpacity: 0.7,
          },
        },
        {
          name: "果桑",
          id: "farmguosang",

          points: guosangtable,
          style: {
            strokeWeight: 1,
            strokeColor: "white",
            fillColor: "white",
            fillOpacity: 0.9,
          },
        },
        {
          name: "黄桃",
          id: "farmhuangtao",

          points: huangtaotable,
          style: {
            strokeWeight: 1,
            strokeColor: "black",
            fillColor: "black",
            fillOpacity: 0.5,
          },
        },
      ];
      // 假设你有一个包含所有 div ID 的数组,或者你可以从 fugaiwudata 中提取它们
      const allDivIds = fugaiwudata.map((item) => item.id); // 提取所有 id

      fugaiwudata.forEach((item, i) => {
        const polygon = new BMap.Polygon(item.points, item.style);
        this.map.addOverlay(polygon);
        // 监听鼠标进入事件
        polygon.addEventListener("click", function (e) {
          // 获取被点击的 div 的 id
          const clickedDivId = item.id; // 确保这个属性在你的数据中存在

          // 遍历所有 div 并隐藏它们
          allDivIds.forEach((divId) => {
            const customDiv = document.getElementById(divId);
            if (customDiv) {
              customDiv.style.display = "none"; // 隐藏所有 div
            }
          });

          // 显示被点击的 div
          const clickedDiv = document.getElementById(clickedDivId);
          if (clickedDiv) {
            clickedDiv.style.display = "block"; // 显示被点击的 div
            clickedDiv.style.position = "fixed";
            clickedDiv.style.left =
              window.innerWidth - clickedDiv.offsetWidth + "px";
            clickedDiv.style.top = "100px"; // 距离页面上边距 100px
          }
        });
      });
    },
    initCharts() {
      this.farmRecords.forEach((farmRecord, index) => {
        this.initChart(farmRecord, index);
      });
    },
    initChart(farmRecord, index) {
      const chartDom = this.$refs[`chartContainer${index}`][0]; // 获取DOM元素
      if (chartDom) {
        const myChart = echarts.init(chartDom); // 初始化图表实例
        const chartOption = this.getPieChartOption(farmRecord); // 获取饼图配置项
        myChart.setOption(chartOption); // 设置图表配置项并渲染图表
      }
    },
    getPieChartOption(farmRecord) {
      // 根据farmRecord的内容返回饼图的配置项
      // 假设farmRecord中包含了绘制饼图所需的数据
      return {
        // title: {
        //   text: `地块${farmRecord.name}的农事记录饼图`
        // },
        tooltip: {
          trigger: "item",
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: "vertical",
          position: "absolute",
          icon: "circle",

          // data: farmRecord.pieChartLegend, // 假设这是饼图图例数据的数组
          top: farmRecord.chartoption.totop,
          right: farmRecord.chartoption.toright,

          formatter: function (name) {
            // 遍历 legend.data 来找到对应项的次数,并显示在图例项旁边
            const item = farmRecord.chartoption.legendData.find(
              (item) => item.name === name
            );
            return item ? `${name} (${item.value}次)` : name;
          },
          textStyle: {
            color: "white",
            fontSize: 15,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "80%"], //饼图半径,第一项内半径,第二项外半径,等半径圆环展示
            center: ["30%", "50%"],
            label: {
              //饼图图形上的文本标签
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: farmRecord.chartoption.legendData, // 假设这是饼图数据的数组，每项是一个对象，包含name和value属性
          },
        ],
      };
    },
  },

  mounted() {
    this.initCharts();
  },
};
</script>

<style lang="less">
#farmnongchang,
#farmxiaolongxia,
#farmxiaomai,
#farmyutang,
#farmdapengshang,
#farmdapengxia,
#farmcaomei,
#farmtiangua,
#farmshuimitao,
#farmshizi,
#farmyingtao1,
#farmyingtao2,
#farmguosang,
#farmhuangtao {
  width: 23vw;
  height: 38vw;

  // display: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-top: 10px solid;

  border-image: linear-gradient(to right, rgba(25, 248, 227, 0.5), transparent)
    1;
  // visibility: hidden;

  display: none;
}
.nongchang,
.xiaolongxia,
.xiaomai,
.dapeng1,
.dapeng2,
.yutang {
  width: 22vw;
  height: 34vw;
  // background-color: red;
  margin-left: 0.5vw;
  margin-top: 0.5vw;
}
.wordwhite {
  color: white;
  font-size: 1.125rem;
  margin-bottom: 1vh;
}
.wordgreen {
  color: rgb(25, 255, 233);
  font-size: 1.125rem;
  margin-bottom: 1vh;
}
.imgandword {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.xiushi {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 2px;
  background-color: rgb(52, 106, 118);
  animation: moveRight 4s linear infinite;
}
@keyframes moveRight {
  0% {
    width: 0;
    opacity: 1;
  }
  // 50% {
  //   width: 9vw;
  //   opacity: 0.5;
  // }
  100% {
    width: 21vw; /* 与 style 属性中设置的宽度一致 */
    opacity: 1;
    transform: translateX(0); /* 恢复到原始位置 */
  }
}
#myChartnongchang,
#myChartxiaolongxia,
#myChartxiaomai,
#myChartyutang,
#myChartdapengshang,
#myChartdapengxia,
#myChartcaomei,
#myCharttiangua,
#myChartshuimitao,
#myChartshizi,
#myChartyingtao1,
#myChartyingtao2,
#myChartguosang,
#myCharthuangtao {
  width: 22vw;
  height: 10vw;
}
.tubiaoword {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}
.fontword {
  width: 85%;
  display: flex;
  justify-content: space-evenly;
  // border: 1px solid #333;
  background-color: rgba(255, 255, 255, 0.1);
}
</style>