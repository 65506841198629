import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 后端接口地址
    url: "api1/xnmgc/getall",

    // 后端得到的数据
    obj:{}
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    
  },
  modules: {
  }
})
