<template>
  <div id="total">
    <div>
      <dv-decoration-9 :color="['rgb(25,255,233)', 'rgb(25,200,233)']"
        ><img src="../assets/温度.png" width="30px"
      /></dv-decoration-9>
    </div>
    <div class="font">土壤温度{{ this.turangdata.wendu }}℃</div>
    <div>
      <dv-decoration-9 :color="['rgb(25,255,233)', 'rgb(25,200,233)']"
        ><img src="../assets/水滴.png" width="30px"
      /></dv-decoration-9>
    </div>
    <div class="font">土壤湿度{{ this.turangdata.shidu }}</div>

    <div>
      <dv-decoration-9 :color="['rgb(25,255,233)', 'rgb(25,200,233)']"
        ><img src="../assets/土壤ec值.png" width="30px"
      /></dv-decoration-9>
    </div>
    <div class="font">土壤ec值{{ this.turangdata.ec }}mS/cm</div>

    <div>
      <dv-decoration-9 :color="['rgb(25,255,233)', 'rgb(25,200,233)']"
        ><img src="../assets/PH值.png" width="30px"
      /></dv-decoration-9>
    </div>
    <div class="font">土壤PH值{{ this.turangdata.ph }}</div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      turangdata: {
        wendu: "",
        shidu: "",
        ec: "",
        ph: "",
      },
    };
  },
  methods: {
    sendReq() {
      axios({
        method: "get",
        url: this.url,
      }).then((res) => {
        // console.log(res.data.data, "bottom");

        // 土壤数据
        this.turangdata.wendu = res.data.data.temp;
        this.turangdata.shidu = res.data.data.hum;
        this.turangdata.ec = res.data.data.ec;
        this.turangdata.ph = res.data.data.ph;
      });
    },
  },
  computed: {
    ...mapState(["url"]),
  },
  mounted() {
    this.sendReq();
    // console.log(this.url, "url");
  },
  activated() {
    this.sendReq();
  },
};
</script>

<style lang="less" scoped>
#total {
  width: 40vw;
  height: 10vh;
  //   background-color: red;
  position: relative;
  top: -85vh;
  display: flex;
  justify-content: space-evenly;
  div {
    width: 4.5vw;
    height: 9vh;
    // border: 1px solid red;
    //line-height: 4.5vh;
    text-align: center;
    font-size: 1rem;
    color: white;
  }
  .font {
    position: relative;
    top: 3vh;
  }
}
</style>