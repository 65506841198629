<template>
  <div id="index">
    <baidu-map id="map" @ready="handler" style="height: 90vh; width: 90vw">
    </baidu-map>
    <div
      id="a"
      v-show="showPopup"
      @click="hidePopup"
      style="
        width: 500px;
        height: 500px;
        background-color: red;
        position: absolute;
      "
    >
      hh
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "Index",
  components: {},
  data() {
    return {
      showPopup: false,
      map: "",
      mapZoom: 18,
      polygon: "",
      inputValue: "",
      dialogVisible: false,
    };
  },

  // mounted() {
  //   let mychart1 = echarts.init(this.$refs.myChart);
  //   let mychart2 = echarts.init(this.$refs.myChart2);

  //   let option = {
  //     tooltip: {
  //       trigger: "item", //触发类型item数据项图形触发
  //     },
  //     legend: {
  //       // orient: "vertical", //图例列表的布局朝向vertical纵向
  //       left: "center",
  //       icon: "circle",
  //       top: 5,
  //       textStyle: {
  //         color: "white",
  //         // fontSize: 20,
  //       },
  //     },
  //     series: [
  //       {
  //         type: "pie", //饼图主要用于表现不同类目的数据在总和中的占比。每个的弧度表示数据数量的比
  //         radius: ["40%", "70%"], //饼图半径，第一项内半径，第二项外半径，等半径圆环展示
  //         // //设置环形图
  //         // label: {
  //         //   //饼图图形上的文本标签
  //         //   show: true,
  //         //   position: "inside", //outside饼图扇区外侧展示文本内容（每个扇形代表的意思），inside饼图扇区上展示文本，center饼图中心，点击不同扇区展示不同文本
  //         //   color: "yellow",
  //         // },
  //         // backgroundColor: "rgba(0, 0, 255, 0.2)",

  //         // roseType: "area", //是否展示成南丁格尔图，通过半径区分数据大小，不等半径圆环展示
  //         // itemStyle: {
  //         //   //设置内容样式
  //         //   color: "#c23531", //统一圆环颜色
  //         //   shadowBlur: 100,
  //         //   shadowColor: "rgba(1,175,80,1)",
  //         // },
  //         label: {
  //           //饼图图形上的文本标签
  //           show: false,
  //           position: "inside", //outside饼图扇区外侧展示文本内容（每个扇形代表的意思），inside饼图扇区上展示文本，center饼图中心，点击不同扇区展示不同文本
  //           color: "yellow",
  //         },
  //         labelLine: {
  //           show: false,
  //         },
  //         data: [
  //           { value: 10, name: "施肥" },
  //           { value: 8, name: "除草" },
  //           { value: 1, name: "授粉" },
  //           { value: 1, name: "其他" },
  //         ],
  //       },
  //     ],
  //   };
  //   let option2 = {
  //     tooltip: {
  //       trigger: "item", //触发类型item数据项图形触发
  //     },
  //     // legend: {
  //     //   // orient: "vertical", //图例列表的布局朝向vertical纵向
  //     //   left: "center",
  //     //   icon: "circle",
  //     //   top: 5,
  //     //   textStyle: {
  //     //     color: "white",
  //     //     // fontSize: 20,
  //     //   },
  //     // },
  //     series: [
  //       {
  //         type: "pie", //饼图主要用于表现不同类目的数据在总和中的占比。每个的弧度表示数据数量的比
  //         radius: ["40%", "70%"], //饼图半径，第一项内半径，第二项外半径，等半径圆环展示
  //         // //设置环形图
  //         // label: {
  //         //   //饼图图形上的文本标签
  //         //   show: true,
  //         //   position: "inside", //outside饼图扇区外侧展示文本内容（每个扇形代表的意思），inside饼图扇区上展示文本，center饼图中心，点击不同扇区展示不同文本
  //         //   color: "yellow",
  //         // },
  //         // backgroundColor: "rgba(0, 0, 255, 0.2)",

  //         // roseType: "area", //是否展示成南丁格尔图，通过半径区分数据大小，不等半径圆环展示
  //         // itemStyle: {
  //         //   //设置内容样式
  //         //   color: "#c23531", //统一圆环颜色
  //         //   shadowBlur: 100,
  //         //   shadowColor: "rgba(1,175,80,1)",
  //         // },
  //         label: {
  //           //饼图图形上的文本标签
  //           show: false,
  //           position: "inside", //outside饼图扇区外侧展示文本内容（每个扇形代表的意思），inside饼图扇区上展示文本，center饼图中心，点击不同扇区展示不同文本
  //           color: "yellow",
  //         },
  //         labelLine: {
  //           show: false,
  //         },
  //         data: [
  //           { value: 10, name: "施肥" },
  //           { value: 8, name: "除草" },
  //           { value: 1, name: "授粉" },
  //           { value: 1, name: "其他" },
  //         ],
  //       },
  //     ],
  //   };
  //   // 4.设置图表绘制图表
  //   mychart1.setOption(option);
  //   mychart2.setOption(option2);

  //   window.addEventListener("resize", () => {
  //     // console.log("变了");
  //     mychart1.resize();
  //     mychart2.resize();
  //   });
  // },
  methods: {
    opendialog() {
      this.dialogVisible = true;
    },
    hhh(lng, lat) {
      lng = 30.731578;
      lat = 119.97544;
      let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
      console.log(x_pi, "x_pi");
      let x = lng;
      let y = lat;
      let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * x_pi);
      console.log(z, "z");

      let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * x_pi);
      console.log(theta, "theta");

      let lngs = z * Math.cos(theta) + 0.0065;
      let lats = z * Math.sin(theta) + 0.006;
      console.log(lngs, lats, "经纬度");
      return {
        lng: lngs,
        lat: lats,
      };
    },
    handler({ BMap, map }) {
      // 创建Map实例，map是id=map
      this.map = new BMap.Map("map");

      var point = new BMap.Point(119.9853, 30.7390608);

      // 添加控件的时候用以下一句话
      // this.map = new BMap.Map("map",{BMAP_SATELLITE_MAP});
      // 初始化地图,设置中心点坐标和地图级别
      this.map.centerAndZoom(point, this.mapZoom);
      // 启用滚轮放大缩小，默认禁用
      this.map.enableScrollWheelZoom(true);

      // 启用地图惯性拖拽，默认禁用
      this.map.enableContinuousZoom(true);
      // 改变地图类型
      this.map.setMapType(BMAP_SATELLITE_MAP);
      this.addmarker();
      // 禁止地图旋转和倾斜可以通过配置项进行设置
    },
    addmarker() {
      // 创建标注,以其他点位坐标,用图片作为标注点的图标，加上icon就是可以显示图片
      var marker = new BMap.Marker(new BMap.Point(119.9853, 30.7390608));
      // 将标注点放到地图中
      this.map.addOverlay(marker);

      marker.addEventListener("click", function (e) {
        console.log("点击了");
        this.showPopup = true;
        // 定位自定义div的位置
        const popupPosition = this.map.pointToOverlayPixel(
          marker.getPosition()
        );
        console.log(popupPosition, "popupPosition");
        const popup = document.getElementById("a");
        console.log(popup, "popup");
        popup.style.left = `${popupPosition.x}px`;
        popup.style.top = `${popupPosition.y}px`;
      });
      // 点击地图其他位置时隐藏自定义div
      this.map.addEventListener("click", () => {
        this.showPopup = false;
      });
    },
    hidePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
#index {
  width: 100vw;
  height: 100vh;
  .top {
    display: flex;
    width: 70%;
    height: 8%;
    // border: 1px solid blue;
    .circle {
      width: 4vw;
      height: 4vw;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      // background-color: red;
      // position: relative;
      // top: -8%;
      // left: 2%;

      // border-radius: 50%;
    }
    .link {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      height: 3.95vw;

      // border: 1px solid red;
      button {
        background: transparent;
        color: white;
        border: none;
        font-size: 20px;
      }
    }

    /deep/ .el-dialog {
      background: rgba(0, 0, 0, 0.5);
      position: relative;
      top: -143px;
      left: 75px;
      // border: 1px solid green;
    }
    .daohangl {
      width: 60%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: space-evenly;
      position: relative;
      // top: 20%;
      // height: 100%;
      padding: 5px;

      div {
        width: 10%;
        // height: 70px;
        // border: 1px solid red;
        pad: 2px;
        font-size: 25px;
        color: white;
        line-height: 70px;
        text-align: center;
      }
    }
    .daohangr {
      position: relative;
      left: -4%;
      width: 90px;
      // height: 95px;
      // background-color: ;
      border-radius: 50%;
      background: linear-gradient(
        to right,
        transparent,
        transparent,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      );
    }
  }
  .middle {
    width: 100%;

    height: 80%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 10px;
    // border: 1px solid red;

    .ml {
      width: 18%;

      .mlt {
        width: 100%;
        margin-bottom: 3%;
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        border-top: 10px solid;

        border-image: linear-gradient(
            to right,
            rgba(25, 248, 227, 0.5),
            transparent
          )
          1;
        .mltall {
          display: flex;
          justify-content: space-around;
          width: 100%;
          // padding: 10px;

          .img {
            width: 30%;
            // border: 1px solid blue;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          .font {
            // height: 150px;
            // line-height: 20px;

            width: 30%;
            // line-height: 500px;
            font-size: 20px;
            color: rgb(25, 255, 233);

            div {
              height: 42px;
            }
          }
        }
      }
      .mlb {
        width: 100%;
        height: 45vh;
        border-top: 10px solid;
        border-image: linear-gradient(
            to right,
            rgba(25, 248, 227, 0.5),
            transparent
          )
          1;
        background-color: rgba(0, 0, 0, 0.5);
        .mlball {
          width: 90%;
          position: relative;
          top: 2%;
          left: 5%;

          // border: 1px solid red;
          .font {
            font-size: 25px;
            font-weight: 800;
            color: rgb(25, 255, 233);
            // border: 1px solid red;
          }
          .mlbt {
            width: 100%;
            .mlbtzuhe {
              width: 100%;
              display: flex;
              justify-content: space-evenly;
              border: 1px solid #333;
              margin-bottom: 5px;
              padding: 2px;
            }
          }
          .mlbb {
            width: 100%;
            #myChart2 {
              width: 100%;
              height: 20vh;
            }
          }
        }

        // border: 1px solid blue;
      }
    }
    .mm {
      width: 54%;
      // border: 1px solid blue;
    }
    .mr {
      width: 23%;
      height: 70vh;
      background-color: rgba(0, 0, 0, 0.5);
      border-top: 10px solid;

      border-image: linear-gradient(
          to right,
          rgba(25, 248, 227, 0.5),
          transparent
        )
        1;
      .mrall {
        width: 100%;
        .mrt {
          width: 100%;

          position: relative;
          top: 10px;
          left: 25px;
          .a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            // border: 1px solid red;
            // padding: 5px;
            div {
              width: 30%;
              color: white;
              font-size: 20px;
            }
          }
          .a1 {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 10px;
            .img {
              width: 30%;
              // border: 1px solid blue;
              margin-top: 5px;
              margin-bottom: 5px;
            }
            .font {
              // height: 150px;
              // line-height: 20px;

              width: 30%;
              // line-height: 500px;
              font-size: 20px;
              color: rgb(25, 255, 233);

              // div {
              //   height: 30px;
              // }
            }
          }
        }
        .mrm {
          width: 100%;

          position: relative;
          top: 10px;
          left: 25px;
          .a {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            // border: 1px solid red;
            // padding: 5px;
            div {
              width: 30%;
              color: white;
              font-size: 20px;
            }
          }
          #myChart {
            width: 100%;
            height: 20vh;
          }
        }
        .mrb {
          width: 95%;
          display: flex;
          justify-content: space-evenly;
          // border: 1px solid blue;
          position: relative;
          top: 20px;
          left: 13px;
          .mrbl {
            width: 20%;
            // border: 1px solid red;
          }
          .mrbr {
            width: 70%;
            .b {
              width: 90%;
              display: flex;
              justify-content: space-between;

              margin-bottom: 20px;
              background-color: rgba(255, 255, 255, 0.1);
              padding: 5px;

              div {
                color: white;
                font-size: 18px;
              }
            }
            // border: 1px solid green;
          }
        }
        // border: 1px solid blue;
      }
      // border: 1px solid blue;
    }
  }
  .bottom {
    width: 100%;

    height: 10%;
    display: flex;
    justify-content: space-between;
    // border: 1px solid black;
    position: relative;
    top: 110px;
    .bl {
      width: 40%;
      display: flex;
      justify-content: space-evenly;
      color: white;
      // border: 1px solid green;

      div {
        width: 70px;
        height: 70px;
        // border: 1px solid red;
        // line-height: 70px;
        text-align: center;
      }
    }

    .br {
      //
      width: 600px;
      height: 30vh;
      position: relative;
      top: -80px;
      left: -10px;
      display: flex;
      justify-content: space-evenly;
      // border: 5px solid black;
      div {
        border: 1px solid black;
      }
    }
  }
}
</style>

