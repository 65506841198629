<template>
  <div id="tou">
    <div class="top">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="middle">
      <div class="name">新农梦工场</div>
      <div class="buttons">
        <div class="bgc">
          <button @click="toshouye()">首页</button>
          <button @click="tojiankong()">物联监控</button>
          <button @click="tostudy()">耕读渔樵</button>
          <button @click="tosell()">销售管理</button>
          <button @click="tohoutai()">后台管理</button>
        </div>
      </div>
      <div class="time">
        <dv-decoration-11 style="width: 130px; height: 50px">
          {{ currentMonthDay }}</dv-decoration-11
        >
      </div>
    </div>
    <div class="bottom">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentMonthDay: "",
    };
  },
  methods: {
    toshouye() {
      this.$router.push("/ccc");
    },
    tojiankong() {
      this.$router.push("/jiankong");
    },
    tostudy() {
      this.$router.push("/study");
    },
    tosell() {
      this.$router.push("/sell");
    },
    tohoutai() {
      this.$router.push("/houtai");
    },
    getCurrentMonthDay() {
      const now = new Date();
      const month = now.getMonth() + 1; // 注意：JavaScript的月份是从0开始的，所以需要加1
      const day = now.getDate();
      const hours = String(now.getHours()).padStart(2, "0"); // 小时转换为字符串并补零
      const minutes = String(now.getMinutes()).padStart(2, "0"); // 分钟转换为字符串并补零
      //   this.currentMonthDay = `${month}月${day}日 ${hours}:${minutes}`;
      this.currentMonthDay = `${hours}:${minutes}`;
    },
  },
  mounted() {
    this.getCurrentMonthDay();
  },
};
</script>

<style lang="less" scoped>
#tou {
  width: 100vw;
  height: 11vh;
}
.top,
.bottom {
  width: 100vw;
  height: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center; /* 为了确保柱子在垂直方向上居中 */

  div {
    width: 16vw;
    height: 1vh;
  }
}
.top div,
.bottom div {
  border-radius: 10px; /* 设置柱子的圆角，根据需要调整 */
  /* 如果需要更立体的效果，可以添加阴影 */
  //   box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
}
.top > div:nth-child(1) {
  background-color: #21394d;
}

.bottom > div:nth-last-child(1) {
  background-color: #21394d;
}

.top > div:nth-child(2) {
  background-color: #21394d;
}

.bottom > div:nth-child(5) {
  background-color: #21394d;
}

.top > div:nth-child(3) {
  background-color: #345276;
}

.bottom > div:nth-child(4) {
  background-color: #345276;
}

.top > div:nth-child(4) {
  background-color: #5580a7;
}

.bottom > div:nth-child(3) {
  background-color: #5580a7;
}

.top > div:nth-child(5) {
  background-color: #88c0e5;
}

.bottom > div:nth-child(2) {
  background-color: #88c0e5;
}
.top > div:nth-child(6) {
  background-color: rgba(206, 246, 255, 0.8);
}

.bottom > div:nth-child(1) {
  background-color: rgba(206, 246, 255, 0.8);
}
.middle {
  width: 100vw;
  height: 8vh;

  display: flex;
  justify-content: space-evenly;
}
.name {
  width: 12%;
  height: 8vh;
  font-size: 2.5rem;
  color: rgba(185, 234, 255, 1);
  font-weight: 800;
  line-height: 8vh;

  //
}
.time {
  width: 15%;
  height: 8vh;
  //   background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(185, 234, 255, 1);
  font-size: 1.5rem;
}

.buttons {
  width: 60%;
  height: 8vh;
  //   background-color: white;
}
.bgc {
  width: 100%;
  line-height: 8vh;
  //   background-color: red;

  button {
    width: 15%;
    line-height: 5vh;
    font-size: 1.5rem;
    margin-right: 3vw;
    color: rgba(185, 234, 255, 1);

    border: none;
    box-shadow: 0 0 1.5vw #1f69b9b9 inset;
    background: linear-gradient(to top, #26aad1, #26aad1) left top no-repeat,
      //上左
      linear-gradient(to right, #26aad1, #26aad1) left top no-repeat,
      //左上
      linear-gradient(to left, #26aad1, #26aad1) right top no-repeat,
      //上右
      linear-gradient(to bottom, #26aad1, #26aad1) right top no-repeat,
      //上右
      linear-gradient(to left, #26aad1, #26aad1) left bottom no-repeat,
      //下左
      linear-gradient(to bottom, #26aad1, #26aad1) left bottom no-repeat,
      //左下
      linear-gradient(to top, #26aad1, #26aad1) right bottom no-repeat,
      //下右
      linear-gradient(to left, #26aad1, #26aad1) right bottom no-repeat; //右下
    background-size: 3px 10px, 10px 3px, 3px 10px, 10px 3px;
  }
}
</style>