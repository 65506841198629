import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import jiankong from '../views/jiankong.vue'
import ccc from '../views/ccc.vue'


Vue.use(VueRouter)

const routes = [

    {
    path: '/',
    name: 'ccc',
    component: ccc
  },
  {
    path: '/ccc',
    name: 'ccc',
    component: ccc,
    component: () => import( '../views/ccc.vue')
  },
  //     {
  //   path: '/',
  //   name: 'jiankong',
  //   component: jiankong,

  // },
  {
    path: '/HomeView',
    name: 'HomeView',

    component: () => import( '../views/HomeView.vue')
  },

  {
    path: '/jiankong',
    name: 'jiankong',
    component: () => import( '../views/jiankong.vue')
  },
  {
    path: '/study',
    name: 'study',

    component: () => import( '../views/study.vue')
  }, {
    path: '/sell',
    name: 'sell',

    component: () => import( '../views/sell.vue')
  }, {
    path: '/houtai',
    name: 'houtai',

    component: () => import( '../views/houtai.vue')
  },
  
]

const router = new VueRouter({
  routes
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}

export default router
