<template>
  <div id="total">
    <div class="bgc">
      <div class="bigtitle">农业气候</div>
      <dv-decoration-2 style="width: 18vw; height: 5px" />
      <div class="qihoudata">
        <div><img src="../assets/温度1.png" width="40px" /></div>
        <div style="color: white; font-size: 1.125rem; line-height: 40px">
          大气温度
        </div>

        <div
          style="
            color: rgb(25, 255, 233);
            font-size: 1.125rem;
            line-height: 40px;
          "
        >
          {{ this.qixiangdata.wendu }}℃
        </div>
      </div>
      <div class="qihoudata">
        <div><img src="../assets/光照度.png" width="40px" /></div>
        <div style="color: white; font-size: 1.125rem; line-height: 40px">
          光照强度
        </div>

        <div
          style="
            color: rgb(25, 255, 233);
            font-size: 1.125rem;
            line-height: 40px;
          "
        >
          {{ this.qixiangdata.guangzahodu }}
        </div>
      </div>
      <div class="qihoudata">
        <div>
          <img
            src="../assets/降雨量1 .png"
            width="40px"
            style="position: relative; left: -10px"
          />
        </div>
        <div style="color: white; font-size: 1.125rem; line-height: 40px">
          降雨量&nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div
          style="
            color: rgb(25, 255, 233);
            font-size: 1.125rem;
            line-height: 40px;
          "
        >
          {{ this.qixiangdata.jiangyuliang }}
        </div>
      </div>
      <div class="qihoudata">
        <div><img src="../assets/二氧化碳1.png" width="40px" /></div>
        <div style="color: white; font-size: 1.125rem; line-height: 40px">
          二氧化碳&nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div
          style="
            color: rgb(25, 255, 233);
            font-size: 1.125rem;
            line-height: 40px;
          "
        >
          {{ this.qixiangdata.eryanghuatan }}
        </div>
      </div>
      <div class="qihoudata" style="margin-bottom: 30px">
        <div><img src="../assets/风速.png" width="40px" /></div>
        <div style="color: white; font-size: 1.125rem; line-height: 40px">
          风的速度&nbsp;&nbsp;&nbsp;&nbsp;
        </div>

        <div
          style="
            color: rgb(25, 255, 233);
            font-size: 1.125rem;
            line-height: 40px;
          "
        >
          {{ this.qixiangdata.fengsu }}
        </div>
      </div>
      <div>
        <label v-for="item in chartItems" :key="item.value">
          <input
            type="radio"
            :value="item.value"
            v-model="selectedContent"
            @change="fetchChartData"
          />
          {{ item.label }}
        </label>
      </div>

      <!-- 根据 selectedContent 显示不同的图表 -->
      <div ref="chartContainer" style="width: 98%; height: 13vw"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedContent: "温度", //选中的内容
      time: "",
      intervalId: null, // 定时器的ID
      qixiangdata: {
        wendu: "",
        guangzahodu: "",
        jiangyuliang: "",
        eryanghuatan: "",
        fengsu: "",
      },
      chartItems: [
        // 单选按钮的数据
        { value: "温度", label: "温度" },
        { value: "光照强度", label: "光照强度" },
        { value: "降雨量", label: "降雨量" },
        { value: "二氧化碳", label: "二氧化碳" },
        { value: "风速", label: "风速" },
      ],
      chartInstance: null, // ECharts实例
      chartData: null, // 从后端获取的图表数据
      dayOfWeek: Number,

      tempdatamax: [],
      tempdatamin: [],
      dataArray: [], // 用于存储从后端获取的数据
      maxValue: null, // 当前最大值
      minValue: null, // 当前最小值
      tempobj: {},
      tempdata: Array(7).fill(null),
      lightdata: Array(7).fill(null),
      raindata: Array(7).fill(null),
      co2data: Array(7).fill(null),
      windspeeddata: Array(7).fill(null),
      currentIndex: 0,
    };
  },
  watch: {
    // dayOfWeek(newVal, oldVal) {
    // console.log(newVal, "newVal");
    // console.log(oldVal, "oldVal");
    // 星期一清空上周数组
    // if (newVal == 1) {
    //   // console.log("jjjjjjjjj");
    //   this.tempdata = [];
    //   this.lightdata = [];
    //   this.raindata = [];
    //   this.co2data = [];
    //   this.windspeeddata = [];
    // }
    // if (newVal !== oldVal) {
    //   console.log(newVal, "newVal");
    //   console.log(oldVal, "oldVal");
    //   console.log("不同");
    // }
    // },
    selectedContent(newVal) {
      console.log(newVal, "radio变了newVal");
      this.updateChart(newVal); // 当选中内容变化时获取数据并更新图表
    },
  },
  computed: {
    ...mapState(["url"]),
  },
  methods: {
    // 初始化 ECharts 实例
    initChart() {
      const chartContainer = this.$refs.chartContainer;
      this.chartInstance = echarts.init(chartContainer);
    },
    fetchChartData() {
      const date = new Date(); // 获取当前日期和时间
      this.dayOfWeek = date.getDay(); // 获取星期几，返回值是0（代表周日）到6（代表周六）

      axios({
        method: "get",
        url: this.url,
      }).then((res) => {
        this.tempdata[this.currentIndex] = res.data.data.temperature.substring(
          0,
          4
        );
        console.log(this.tempdata, "this.tempdata");
        this.lightdata[this.currentIndex] = res.data.data.light;
        this.co2data[this.currentIndex] = res.data.data.co2;
        this.windspeeddata[this.currentIndex] =
          res.data.data.windspeed.substring(0, 4);
        this.raindata[this.currentIndex] = res.data.data.rain;
        // 如果用currentIndex，必须加上下面一句，不然不行
        this.currentIndex = (this.currentIndex + 1) % 7;
        console.log(this.currentIndex, " this.currentIndex");
        // 更新图表数据
        if (this.selectedContent == "温度") {
          this.chartData = this.tempdata;
        } else if (this.selectedContent == "光照强度") {
          this.chartData = this.lightdata;
        } else if (this.selectedContent == "降雨量") {
          this.chartData = this.raindata;
        } else if (this.selectedContent == "二氧化碳") {
          this.chartData = this.co2data;
        } else if (this.selectedContent == "风速") {
          this.chartData = this.windspeeddata;
        }

        this.updateChart();
      });
    },
    updateChart() {
      // if (this.chartData && this.chartInstance) {
      // }
      // 根据选中的内容设置图表的数据和选项
      const option = {
        grid: {
          left: "50px",
        },
        tooltip: {},
        xAxis: {
          type: "category",
          axisLabel: {
            // show: true, //是否显示X轴的内容，就是X轴定义的数据是否显示
            color: "white", //X轴的文本内容的颜色
          },
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false, //是否显示网格线，只能在Y轴中设置，在X轴中设置不好使
          },
          axisLabel: {
            // show: true, //是否显示X轴的内容，就是X轴定义的数据是否显示
            color: "white", //X轴的文本内容的颜色
          },
        },

        series: [
          {
            name: this.selectedContent,
            data: this.chartData, // 示例数据
            type: "line",
            smooth: true, //曲线平滑
            //折线图的高亮状态。
            emphasis: {
              focus: "series", //聚焦当前高亮的数据所在的系列的所有图形
            },
          },
        ],
      };

      this.chartInstance.setOption(option);
    },
    sendReq() {
      // console.log("yyyy");
      axios({
        method: "get",
        url: this.url,
      }).then((res) => {
        // console.log(res.data.data, "left");

        // 气象数据
        this.qixiangdata.wendu = res.data.data.temperature.substring(0, 4);
        this.qixiangdata.guangzahodu = res.data.data.light;
        this.qixiangdata.jiangyuliang = res.data.data.rain;
        this.qixiangdata.eryanghuatan = res.data.data.co2;
        this.qixiangdata.fengsu = res.data.data.windspeed.substring(0, 4);

        // this.tempdata[this.currentIndex] = res.data.data.temperature.substring(
        //   0,
        //   4
        // );
      });
    },
    hhh() {
      axios({
        method: "get",
        url: this.url,
      }).then((res) => {
        console.log(res.data.data.temperature, "res.data.data.temperature");
        this.tempdata[this.currentIndex + 4] = res.data.data.temperature;
        console.log(res.data.data.temperature, "res.data.data.temp");
        this.currentIndex = (this.currentIndex + 1) % 7; // 更新索引，如果超过数组长度则从头开始
        console.log(this.tempdata, "this.tempdata");
      });
    },
  },
  beforeDestroy() {
    // 组件销毁前清除定时器
    // clearInterval(this.intervalId);
  },
  created() {
    // this.checkAndRequestData();
  },
  mounted() {
    // 初始化 ECharts 实例
    this.initChart();
    // 根据初始选中的图表类型获取数据
    this.fetchChartData();
    setInterval(() => this.fetchChartData(), 24 * 60 * 60 * 1000);
    // setInterval(this.fetchChartData, 24 * 60 * 60 * 1000);
    // setInterval(this.fetchChartData, 2000); // 设置定时器
    this.sendReq();
  },
  activated() {
    this.sendReq();
  },
};
</script>

<style lang="less" scoped>
#total {
  width: 21vw;
  height: 33vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  top: -50vw;
  border-top: 10px solid;

  border-image: linear-gradient(to right, rgba(25, 248, 227, 0.5), transparent)
    1;
}
.bgc {
  margin-left: 10px;
  margin-top: 10px;
}
.bigtitle {
  font-size: 1.5rem;
  color: rgb(25, 255, 233);
  font-weight: 600;
  margin-bottom: 10px;
}
.qihoudata {
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #333;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 2px;
}
#myChart1 {
  width: 98%;
  height: 13vw;
  margin-top: 1vw;
  // background-color: red;
}
#myChart2 {
  width: 98%;
  height: 13vw;
  margin-top: 1vw;
  // background-color: red;
}
#chartContainer {
  width: 98%;
  height: 13vw;
  // margin-top: 1vw;
}

label {
  color: white;
  margin-top: 4vw;
}
</style>