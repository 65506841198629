<template>
  <div id="total">
    <button @click="opendialog" class="daohangimg">
      <img src="../assets/菜单.png" style="height: 4vw" />
    </button>
    <el-dialog :visible.sync="dialogVisible" :modal="false" :show-close="false">
      <div class="bgc">
        <!-- <button
          @click="
            closedialog();
            toshouye();
          "
        >
          首页
        </button> -->
        <button
          @click="
            closedialog();
            tojiankong();
          "
        >
          物联监控
        </button>
        <button
          @click="
            closedialog();
            tostudy();
          "
        >
          耕读渔樵
        </button>
        <button
          @click="
            closedialog();
            tosell();
          "
        >
          销售管理
        </button>
        <button
          @click="
            closedialog();
            tohoutai();
          "
        >
          后台管理
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    opendialog() {
      this.dialogVisible = true;
    },
    closedialog() {
      this.dialogVisible = false;
    },
    toshouye() {
      this.$router.push("/shouye");
    },
    tojiankong() {
      this.$router.push("/jiankong");
    },
    tostudy() {
      this.$router.push("/study");
    },
    tosell() {
      this.$router.push("/sell");
    },
    tohoutai() {
      this.$router.push("/houtai");
    },
  },
};
</script>

<style lang="less" scoped>
#total {
  position: relative;
  top: -100vh;
}
.daohangimg {
  width: 4vw;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
}
.bgc {
  width: 35vw;
  height: 4.2vw;
  line-height: 4.2vw;
  display: flex;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  top: -11.9vw;
  left: 4vw;
  button {
    border: none;
    font-size: 1.6rem;
    background: transparent;

    color: white;
  }
}
</style>